import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Tab, Divider, Tabs, Typography, Box, Grid } from "@material-ui/core";
import InvestmentBanking from "components/WhatWeDoTabs/InvestmentBanking";
import MicroFinanceBanking from "components/WhatWeDoTabs/MicroFinanceBanking";
import Remittance from "components/WhatWeDoTabs/Remittance";
import BreaueDeChange from "components/WhatWeDoTabs/BreaueDeChange";
import Products from "components/WhatWeDoTabs/Products";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    justifyContent: "center",
    height: 320,
    marginTop: "4rem",
  },
  tabs: {
    // border: `1px solid ${theme.palette.divider}`,
    background: "#4F88C7",
    color: "#ffffff",
    borderRadius: "4px",
    width: "20vw",
    fontSize: "1rem",
    textTransform: "capitalize !important",

    "&.active": {
      background: "black",
      border: "none",
      color: "#4F88C7",
    },
  },
  tabPanels: {
    border: "1px solid #4F88C7",
    width: "40vw",
    borderRadius: "4px",
  },
  label: {},
  divider: {
    border: "1px solid #ffffff",
    width: "100%",
  },
  heading: {
    position: "relative",
    bottom: "-42px",
    left: "275px",
    color: "#131747",
    // fontWeight:"bold",
  },
  undergroundLayer: {
    position: "relative",
    bottom: "15px",
    left: "120px",
    width: "140px",
    height: "72px",
    backgroundColor: "#F6A71B",
    background: "#F6A71B",
    borderRadius: "16px",
    zIndex: -1,
  },
}));

const WhatWeDo = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Grid>
        <Typography variant="h6" className={classes.heading}>
          <strong>What</strong> We <strong>Do</strong>
        </Typography>
      </Grid>

      <div className={classes.root}>
        <div className={classes.undergroundLayer}></div>
        <Tabs
          orientation="vertical"
          // variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
          classes={{ label: classes.label }}
        >
          <Tab label="Investment Banking" {...a11yProps(0)} />
          <Divider className={classes.divider} />
          <Tab label="Microfinance Banking" {...a11yProps(1)} />
          <Divider className={classes.divider} />
          <Tab label="Remittance" {...a11yProps(2)} />
          <Divider className={classes.divider} />
          <Tab label="Bureau de Change" {...a11yProps(3)} />
          <Divider className={classes.divider} />
          <Tab label="Products" {...a11yProps(4)} />
          <Divider className={classes.divider} />
        </Tabs>

        <div className={classes.tabPanels}>
          <TabPanel value={value} index={0}>
            <InvestmentBanking />
          </TabPanel>
          <TabPanel value={value} index={1}></TabPanel>
          <TabPanel value={value} index={2}>
            <MicroFinanceBanking />
          </TabPanel>
          <TabPanel value={value} index={3}></TabPanel>
          <TabPanel value={value} index={4}>
            <Remittance />
          </TabPanel>
          <TabPanel value={value} index={5}></TabPanel>
          <TabPanel value={value} index={6}>
            <BreaueDeChange />
          </TabPanel>
          <TabPanel value={value} index={7}></TabPanel>
          <TabPanel value={value} index={8}>
            <Products />
          </TabPanel>
        </div>
      </div>
    </>
  );
};

export default WhatWeDo;
