import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  Grid,
  CardContent,
  CardMedia,
  Typography,
  Container,
  Button,
} from "@material-ui/core";
import { Images } from "../../shared/themes";
import NewsLetter from "../NewsLetter";

const recentCards = [
  {
    image: `${Images.monieTree}`,
    title: "New Product Release",
    Description:
      "We are proud to announce the release of the latest savings and investment product… More",
  },
  {
    image: `${Images.amfb}`,
    title: "AMFB on the Rise",
    Description:
      "Microfinance banking has a new face. The Assests Microfinace group is seeting out with…More",
  },
  {
    image: `${Images.teamBonding}`,
    title: "Team Bonding",
    Description:
      "At Canary, we prioritize the well-being of our staff and place a high priority of company culture…More",
  },
  {
    image: `${Images.blankImage}`,
    title: "New Product Release",
    Description:
      "We are proud to announce the release of the latest savings and investment product…More",
  },
  {
    image: `${Images.blankImage}`,
    title: "New Product Release",
    Description:
      "We are proud to announce the release of the latest savings and investment product…More",
  },
  {
    image: `${Images.blankImage}`,
    title: "New Product Release",
    Description:
      "We are proud to announce the release of the latest savings and investment product…More",
  },
];

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    width: 300,
    height: 380,
    display: "flex",
    marginTop: "3rem",
  },

  cardImage: {
    width: "100%",
    height: "180px",
    objectFit: "contain",
  },
  recentCards: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "1rem",
    borderRadius: "8px",
  },
  viewMoreBtn: {
    padding: ".5rem 3rem",
    borderRadius: "32px",
    margin: "64px",
    background: "linear-gradient(183.92deg, #F2A54E -38.53%, #E06C26 156.47%)",
    color: "#ffffff",
  },
  containerStyles: {
    padding: "5rem",
  },
});

const Recent = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.containerStyles}>
        <Grid container>
          <Grid item xs={12} className={classes.recentCards}>
            {recentCards.map((item) => {
              return (
                <div>
                  <Card className={classes.root}>
                    <CardActionArea>
                      <CardMedia>
                        <img
                          src={item.image}
                          alt="monieTree"
                          className={classes.cardImage}
                        />
                      </CardMedia>

                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          {item.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {item.Description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </div>
              );
            })}
          </Grid>
          <Grid container justify="center">
            <Grid item>
              <Button
                variant="contained"
                className={classes.viewMoreBtn}
                classes={{ label: classes.label }}
              >
                View More
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <NewsLetter />
      </Container>
    </>
  );
};

export default Recent;
