import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Images } from "../../shared/themes";
import { Container } from "@material-ui/core";

const HomeCarousel = () => {
  return (
    <Container>
      <Carousel style={{ width: "100%", height: "10vh" }}>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ paddingTop: "8rem", textAlign: "left" }}>
              <h2 style={{ color: "#131747" }}>
                <strong>Financial</strong> Solutions <br /> for{" "}
                <strong>Tomorrow</strong>
              </h2>
              <p style={{ color: "#6E6E6E" }}>
                We are constantly innovating to bring you the best
                <br /> financial solutions in the market.
              </p>
            </div>
            <div style={{ objectFit: "contain" }}>
              <img src={Images.financialSolutions} alt="financial solutions" />
            </div>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ paddingTop: "8rem", textAlign: "left" }}>
              <h2 style={{ color: "#131747" }}>
                <strong>Patnership</strong> for
                <br /> <strong>Better</strong> Finances
              </h2>
              <p style={{ color: "#6E6E6E" }}>
                We are constantly innovating to bring you the best
                <br /> financial solutions in the market.
              </p>
            </div>
            <div>
              <img src={Images.patnership} alt="patnership" />
            </div>
          </div>
        </div>
      </Carousel>
    </Container>
  );
};

export default HomeCarousel;
