import { Grid, Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Images } from "../../shared/themes";

const useStyles = makeStyles((theme) => ({
  subsidiariesContainer: {
    margin: "18rem 0 14rem",
    display: "flex",
    justifyItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      margin: "8rem 0 4rem",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      margin: "8rem 0 4rem",
    },
  },
  subsidiariesLogos: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      dispaly: "none",
    },
  },
  subsidiariesHeader: {
    fontWeight: "bold",
    color: "#131747",
  },
  subsidiariesTypography: {
    color: "#6E6E6E",
  },
}));

const Subsidiaries = () => {
  const classes = useStyles();
  return (
    <Container>
      <Grid container justify="center">
        <Grid justify="center" className={classes.subsidiariesContainer}>
          <div className={classes.subsidiariesLogos}>
            <Grid Item>
              <img
                src={Images.monie_tree}
                alt="subsidiaries"
                style={{ position: "absolute", top: "1500px", left: "490px" }}
              />
            </Grid>
            <Grid Item>
              <img
                src={Images.nairagram}
                alt="subsidiaries"
                style={{ position: "absolute", top: "1490px", left: "340px" }}
              />
            </Grid>
            <Grid Item>
              <img
                src={Images.instantCash}
                alt="subsidiaries"
                style={{ position: "absolute", top: "1390px", left: "240px" }}
              />
            </Grid>

            <Grid Item>
              <img
                src={Images.assets}
                alt="subsidiaries"
                style={{ position: "absolute", top: "1250px", left: "240px" }}
              />
            </Grid>
            <Grid Item>
              <img
                src={Images.swiftandallied}
                alt="subsidiaries"
                style={{ position: "absolute", top: "1150px", left: "340px" }}
              />
            </Grid>
            <Grid Item>
              <img
                src={Images.sebastian}
                alt="subsidiaries"
                style={{ position: "absolute", top: "1130px", left: "490px" }}
              />
            </Grid>
            <Grid Item>
              <img
                src={Images.canaryPointLogo}
                alt="subsidiaries"
                style={{ paddingLeft: "12rem" }}
              />
            </Grid>
          </div>

          <Grid xs={12} md={8}>
            <Typography variant="h5" className={classes.subsidiariesHeader}>
              Our Subsidiaries
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.subsidiariesTypography}
            >
              CPCSL is a holding company for various other companies <br />
              that offer a range of investment and financial services to <br />
              the public with the aim of offering effective money
              <br /> management.<a href="/">…More</a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Subsidiaries;
