import WhatWeDo from "../components/HomePageFolder/WhatWeDo";
import Subsidiaries from "../components/HomePageFolder/Subsidiaries";
import RecentEvents from "../components/HomePageFolder/RecentEvents";
import HomeCarousel from "../components/HomePageFolder/HomeCarousel";
import NewsLetter from "../components/NewsLetter";

import { Container } from "@material-ui/core";

const Home = () => {
  return (
    <>
      <Container style={{ overflow: "hidden" }}>
        <HomeCarousel />
        <WhatWeDo />
        <Subsidiaries />
        <RecentEvents />
        <div style={{ margin: "0 3rem 2rem", padding: "0 3rem 3rem" }}>
          <NewsLetter />
        </div>
      </Container>
    </>
  );
};

export default Home;
