import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
  },
  tabImageStyles: {
    width: "100%",
    height: "210px !important",
    [theme.breakpoints.down("xs")]: {
      height: "100vh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
    },
  },
}));

export default useStyles;
