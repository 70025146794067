import { Images } from "../../shared/themes";
import { Container, Typography } from "@material-ui/core";
import useStyles from "./WhatWeDoStyles";

const Products = () => {
  const classes = useStyles();
  return (
    <>
      <Container>
        <Typography variant="body2" gutterBottom className={classes.root}>
          We are constantly innovating to provide the best finacial products in
          the market. Our current product offerings range from loans to savings
          and payments.
          <a href="/">Find out more</a>
        </Typography>
        <img
          src={Images.productsImg}
          alt="atm card"
          className={classes.tabImageStyles}
        />
      </Container>
    </>
  );
};
export default Products;
