import caroimg from "../../assets/images/Carouselimg.png";
export const carouselData = [
  {
    name: "Gold Zena",
    title: "Digital Marketer",
    id: 1,
    img: caroimg,
    text:
      "“I’ve gotten all the support I need to excel at my job. The best part of my work experience is how receptive management is to receiving feedback and suggestions based on my expertise, it’s rare to see such flexibility in a financial instituition.”",
  },

  {
    name: "Rasheed Iskilu",
    title: "ICT Specialist",
    id: 2,
    img: caroimg,
    text:
      "“I’ve gotten all the support I need to excel at my job. The best part of my work experience is how receptive management is to receiving feedback and suggestions based on my expertise, it’s rare to see such flexibility in a financial instituition.”",
  },
  {
    name: "Joseph Olaoye",
    title: "CTO Canary Point",
    id: 3,
    img: caroimg,
    text:
      "“I’ve gotten all the support I need to excel at my job. The best part of my work experience is how receptive management is to receiving feedback and suggestions based on my expertise, it’s rare to see such flexibility in a financial instituition.”",
  },
];

export const listenerCircle = [<div></div>, <div></div>, <div></div>];
