import { Images } from "../../shared/themes";
import { Container, Typography } from "@material-ui/core";
import useStyles from "./WhatWeDoStyles";

const BreaueDeChange = () => {
  const classes = useStyles();
  return (
    <>
      <Container>
        <Typography variant="body2" gutterBottom className={classes.root}>
          Our Bureau De Change business is tailored to meet the needs of our
          clients, need for speed and convenience. We are regulated by the CBN…
          <a href="/">Find out more</a>
        </Typography>
        <img
          src={Images.bdc_Img}
          alt="atm card"
          className={classes.tabImageStyles}
        />
      </Container>
    </>
  );
};
export default BreaueDeChange;
