import { makeStyles } from "@material-ui/core/styles";
import { Grid, InputBase, Box, Button, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  input: {
    marginLeft: "2rem",
    flex: 1,
  },
  searchContent: {
    display: "flex",
    border: "1px solid #979797",
    width: 350,
    height: "30px",
    margin: "2rem",
    justifyContent: "center",
    borderRadius: "6px",
  },
  iconButton: {
    padding: "8px 16px",
    color: "#ffffff",
    background: "linear-gradient(180deg, #43DDF7 0%, #20B8EB 100%)",
  },
  label: {
    textTransform: "initial",
  },
  newsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #979797",
    padding: "4rem",
    borderRadius: "8px",
  },
  newsText: {
    width: "100%",
    textAlign: "center",
    color: "#6E6E6E",
  },
});

const NewsLetter = () => {
  const classes = useStyles();
  return (
    <Grid container justify="center" className={classes.newsContainer}>
      <Grid item>
        <Typography variant="h5" className={classes.newsText}>
          Stay updated with the Canary <br /> weekly newsletter!
        </Typography>
      </Grid>
      <Grid item>
        <Box className={classes.searchContent}>
          <InputBase
            className={classes.input}
            // inputProps={{ "aria-label": "email address" }}
            placeholder="email address"
          />
          <Button
            variant="contained"
            type="submit"
            className={classes.iconButton}
            classes={{
              label: classes.label,
            }}
            aria-label="email address"
          >
            Sign Up
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NewsLetter;
