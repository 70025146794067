import { Images } from "../../shared/themes";
import { Container, Typography } from "@material-ui/core";
import useStyles from "./WhatWeDoStyles";

const MicroFinanceBanking = () => {
  const classes = useStyles();
  return (
    <>
      <Container>
        <Typography variant="body2" gutterBottom className={classes.root}>
          Our highly-skilled Investment Banking team work across various asset
          classes, industries and currencies to provide first class investment
          services. <a href="/">Find out more</a>
        </Typography>
        <img
          src={Images.microFinanceBankingImg}
          alt="atm card"
          className={classes.tabImageStyles}
        />
      </Container>
    </>
  );
};
export default MicroFinanceBanking;
