import { Images } from "../../shared/themes";
import { Container, Typography } from "@material-ui/core";
import useStyles from "./WhatWeDoStyles";

const Remittance = () => {
  const classes = useStyles();
  return (
    <>
      <Container>
        <Typography variant="body2" gutterBottom className={classes.root}>
          We understand that the Nigerian community needs to send money home and
          we have created an instant payment gateway.
          <a href="/">Find out more</a>
        </Typography>
        <img
          src={Images.remittanceImg}
          alt="atm card"
          className={classes.tabImageStyles}
        />
      </Container>
    </>
  );
};
export default Remittance;
