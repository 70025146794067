import React, { useLayoutEffect } from "react";
import CareerJobForm from "./CareerJobForm";
import CareersJob from "./CareersJob";
import Interested from "./Interested";
import WhatWeNeed from "./WhatWeNeed";

const Job = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <CareersJob />
      <Interested />
      <WhatWeNeed />
      <CareerJobForm />
    </div>
  );
};
export default Job;
